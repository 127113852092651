import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import "@/assets/styles/common.less"; // 全局样式
import 'animate.css';
import 'hover.css';

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
});

createApp(App).use(router).use(Antd).mount('#app')
