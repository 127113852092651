<template>
  <a-config-provider :locale="locale">
    <div class="app_main" :class="{ mobile: device === 'mobile' }">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import { ref, provide, watchEffect } from "vue";
import { useWindowSize } from "@vueuse/core";

import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  components: {},
  setup() {
    const locale = ref(zhCN);
    const device = ref("");
    const { width } = useWindowSize();
    const WIDTH = 992;
    watchEffect(() => {
      if (width.value - 1 < WIDTH) {
        device.value = "mobile";
      } else {
        device.value = "desktop";
      }
    });
    // 向后代provide出去数据
    provide("device", device);
    return {
      locale,
      device,
    };
  },
};
</script>

<style>
.app_main:not(.mobile) {
  min-width: 1200px;
}
</style>
