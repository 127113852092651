import {createRouter, createWebHistory} from 'vue-router'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:catchAll(.*)",
      redirect: "/index"
    },
    {
      path:'/index',
      name:'index',
      component:()=> import('@/views/authpro'),
      meta: {
        title:"首页"
      }
    }
  ],
  scrollBehavior(){
    return {top:0}
  }
})